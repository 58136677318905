<template>
  <div>
    <div class="container" v-if="!loading">
      <div class="card border-0">
        <div class="card-body">
          <div class="row mb-2">
            <div class="col my-auto">
              <h5 class="fw-bold text-success mb-0">
                Retreat Stays with Amchara
              </h5>
            </div>
            <div class="col-auto my-auto ms-auto">
              <button
                class="btn btn-sm btn-outline-success me-3"
                v-tooltip.bottom="'Sort by newest arrival'"
                @click="sort('desc')"
              >
                <i class="far fa-sort-numeric-down"></i>
              </button>
              <button
                class="btn btn-sm btn-outline-success"
                v-tooltip.bottom="'Sort by oldest arrival'"
                @click="sort('asc')"
              >
                <i class="far fa-sort-numeric-up"></i>
              </button>
            </div>
          </div>
          <div
            class="card border-0 shadpw-sm bg-light mb-4"
            v-for="b in bookings"
            :key="b.id"
          >
            <div class="card-body">
              <div class="row mb-3 text-center">
                <div class="col-md-3">
                  <div class="card border-0 shadow-none">
                    <div class="card-body">
                      <h6 class="fw-bold text-success">Retreat</h6>
                      <h5 class="fw-bold">
                        {{
                          b.retreat == "uk"
                            ? "Amchara Somerset"
                            : "Amchara Gozo"
                        }}
                      </h5>
                    </div>
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="card border-0 shadow-none">
                    <div class="card-body">
                      <h6 class="fw-bold text-success">Arrival</h6>
                      <h5 class="fw-bold">
                        {{ b.arrival_date | formatDate }}
                      </h5>
                    </div>
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="card border-0 shadow-none">
                    <div class="card-body">
                      <h6 class="fw-bold text-success">Departure</h6>
                      <h5 class="fw-bold">
                        {{ b.departure_date | formatDate }}
                      </h5>
                    </div>
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="card border-0 shadow-none">
                    <div class="card-body">
                      <h6 class="fw-bold text-success">Payment</h6>
                      <h5 class="fw-bold text-capitalize">
                        {{ b.payment_status | filterStatus }}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col my-auto">
                  <p class="mb-0">
                    <small
                      >{{ b.clients.length }} client(s) arriving in
                      {{ b.retreat == "uk" ? "the UK" : "Malta" }} on
                      {{ b.room_bookings[0].arrival_date | formatDate }} and
                      leaving on
                      {{ b.room_bookings[0].departure_date | formatDate }}, for
                      a total price of £{{ b.total_cost.toFixed(2) }}</small
                    >
                  </p>
                </div>
                <div class="col-auto my-auto ms-auto">
                  <router-link
                    class="btn btn-success btn-sm"
                    :to="'/bookings/' + b.id"
                    ><i class="far fa-calendar-day me-2"></i>View
                    Booking</router-link
                  >
                </div>
              </div>
            </div>
          </div>

          <div v-if="bookings.length == 0">
            <div class="row text-center my-5">
              <div class="col">
                <h6 class="fw-bold text-success">
                  You don't currently have any retreat stays with Amchara
                </h6>
                <a
                  href="https://amchara.com"
                  target="_blank"
                  class="btn btn btn-outline-success mt-4"
                  >Book Online</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <loading v-else></loading>
  </div>
</template>

<script>
import Loading from "../../components/loaders/Loading";

export default {
  data() {
    return {
      bookings: [],
      loading: false,
    };
  },
  methods: {
    sort(direction) {
      if (direction == "desc") {
        this.bookings.sort((a, b) => {
          return new Date(b.arrival_date) - new Date(a.arrival_date);
        });
      } else {
        this.bookings.sort((a, b) => {
          return new Date(a.arrival_date) - new Date(b.arrival_date);
        });
      }
    },
    fetchBookings() {
      this.loading = true;
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/c/bookings")
        .then(({ data }) => {
          this.bookings = data.sort((a, b) => {
            return new Date(b.arrival_date) - new Date(a.arrival_date);
          });
          this.loading = false;
        });
    },
  },
  mounted() {
    this.fetchBookings();
  },
  filters: {
    formatDate(date) {
      return moment(date).format("LL");
    },
    filterStatus(status) {
      return status.replace("_", " ");
    },
  },
  components: {
    Loading,
  },
};
</script>

<style></style>
